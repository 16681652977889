<template>
    <div>
        <div class="comment_container" v-for="(review, index) in reviewsList" :key="review.id" :class="index < reviewsList.length -1 ? 'comment_container_bottom_border': ''">
            <span v-if="review.user" class="reviewed_user_name"> {{ review.user.email }} </span>
            <i v-for="(star, index) in getStars(review.rating)" :key="index" :class="star"></i>
            <div class="user_comment_date">{{setDateFormat(review.created_at)}}</div>
            <div class="user_comment">{{ review.comment }}</div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'reviewComponent',
    props: ["reviewsList"],
    data() {
        return {
        }
    },
}
</script>