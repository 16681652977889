// src/mixins/mixin.js
export const globalMixin = {
    methods: {
        /**
         * Generates an array of star classes based on a given rating.
         * The rating is represented with full, half, and empty stars.
         *
         * @param {number} rating - The rating value, expected to be between 0 and 5.
         * @returns {string[]} An array of star classes representing the rating.
         */
        getStars(rating) {
            // Initialize an empty array to store the star classes
            const stars = [];

            // Calculate the number of full stars (integer part of the rating)
            const fullStars = Math.floor(rating);

            // Determine if there should be a half star (fractional part of the rating)
            const halfStars = rating % 1 >= 0.5 ? 1 : 0;

            // Calculate the number of empty stars to make the total 5
            const emptyStars = 5 - fullStars - halfStars;

            // Add the classes for full stars to the array
            for (let i = 0; i < fullStars; i++) {
                stars.push('fa-solid fa-star active');
            }

            // Add the class for the half star to the array, if applicable
            for (let i = 0; i < halfStars; i++) {
                stars.push('fa-regular fa-star-half-stroke active');
            }

            // Add the classes for empty stars to the array
            for (let i = 0; i < emptyStars; i++) {
                stars.push('fa-regular fa-star');
            }

            // Return the array of star classes
            return stars;
        },

        /**
         * Formats a date string into the format dd/mm/yyyy.
         *
         * @param {string} dateString - The date string to be formatted.
         * @returns {string} The formatted date string or the original input if an error occurs.
        */
        setDateFormat(dateString) {
            try{
                // Create a new Date object from the date string
                const date = new Date(dateString);

                // Get the day, month, and year from the Date object
                const day = String(date.getDate()).padStart(2, '0');
                const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
                const year = date.getFullYear();

                // Format the date in dd/mm/yyyy
                return `${day}/${month}/${year}`;

            } catch(err){

                return dateString;
            }
        },

        /**
         * Checks if the product is in stock.
         *
         * @param {Object} product - The product object to check.
         * @param {number} product.type - The type of the product (1 or 2).
         * @param {number} product.stock - The stock quantity of the product.
         * @returns {boolean} - Returns true if the product is in stock, otherwise false.
         */
        isProductInStock(product) {
            // If the product type is 2, or if the product type is 1 and stock is 0, return false (out of stock)
            if (product.type == 2 || (product.type == 1 && product.stock == 0)) {
                return false;
            }

            // Otherwise, return true (in stock)
            return true;
        },
        /**
         * Replaces all spaces in a given string with the specified delimiter.
         *
         * @param {string} text - The string in which spaces will be replaced.
         * @param {string} [delimiter="-"] - The string to replace spaces with. Defaults to "-".
         * @returns {string} The modified string with spaces replaced by the delimiter.
         */
        replaceSpaces(text, delimiter = "-") {
            // Split the text into an array of words, then join the array with the delimiter.
            return text.split(" ").join(delimiter);
        }


    }
}
