<template>
    <main class="news_wrapper">
        <div class="page-head">
            <div class="container">
                <router-link to="/">{{ lang == 'en' ? 'Home' : 'الرئيسية' }}</router-link> <i :class="lang == 'en' ? 'fa-solid fa-chevron-right' : 'fa-solid fa-chevron-left'"></i> {{ lang == 'en' ? 'News' : 'الاخبار' }}
            </div>
        </div>
        <div class="container">
            <section class="news">
                <div class="news" v-if="news">
                    <div class="news-card" v-for="article in news" :key="article.id">
                        <div class="thumbanail">
                            <img :src="article.img" alt="article image">
                        </div>
                        <div class="text">
                            <h1 class="title">{{ article.title }}</h1>
                            <p class="bref" v-html="article.desc.length >= 750 ? article.desc.slice(0, 750) + '...' : article.desc">
                            </p>
                            <div>
                                <span class="date"><i class="fa-regular fa-calendar-days"></i> {{formatDate(article.created_at)}}</span>
                                <router-link  :to="`/news/${article.id}`" class="read-more">{{ lang == 'en' ? 'Read More' : 'عرض المزيد' }} <i :class="lang == 'en' ? 'fa-solid fa-chevron-right' : 'fa-solid fa-chevron-left'"></i></router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <!-- <div class="pagination" v-if="last_page > 1">
                <div v-for="page_num in 20" :key="page_num" >
                    <label :for="`page_num_${page_num}`" :class="page_num == page ? 'active' : ''">{{ page_num }}</label>
                    <input type="radio" name="page_num" :id="`page_num_${page_num}`" v-model="page" :value="page_num" @change="getNews(page_num)">
                </div>
            </div> -->

            <div class="pagination" v-if="last_page > 1" style="flex-wrap: wrap;">
            <div v-for="pageNumber in displayedPages" :key="pageNumber">
                <label :for="`page_num_${pageNumber}`" :class="pageNumber == page ? 'active' : ''" @click="goToTop">{{ pageNumber }}</label>
                <input type="radio" name="page_num" :id="`page_num_${pageNumber}`" v-model="page" :value="pageNumber" @change="getNews(pageNumber)">
            </div>
            </div>

        </div>
    </main>
</template>

<script>
global.jQuery = require('jquery');
var $ = global.jQuery;
window.$ = $;

import axios from 'axios';

export default {
    name: 'NewsView',
    data() {
        return {
            questions: null,
            news: null,
            total: 0,
            last_page: 1,
            page: 1,
            lang: 'en'
        }
    },
    methods: {
        async getNews(page) {
            $('.loader').fadeIn().css('display', 'flex')
            try {
                const response = await axios.get(`https://api.egyptgamestore.com/api/news?per_page=20&page=${page}`,
                {
                    headers: {
                        "lang": this.lang
                    }
                }
                );
                if (response.data.status === true) {
                    $('.loader').fadeOut()
                    this.news = response.data.data.news
                    this.total = response.data.data.total
                    this.last_page = response.data.data.last_page
                } else {
                    $('.loader').fadeOut()
                    document.getElementById('errors').innerHTML = ''
                    $.each(response.data.errors, function (key, value) {
                        let error = document.createElement('div')
                        error.classList = 'error'
                        error.innerHTML = value
                        document.getElementById('errors').append(error)
                    });
                    $('#errors').fadeIn('slow')

                    setTimeout(() => {
                        $('input').css('outline', 'none')
                        $('#errors').fadeOut('slow')
                    }, 3500);
                }

            } catch (error) {
                document.getElementById('errors').innerHTML = ''
                let err = document.createElement('div')
                err.classList = 'error'
                err.innerHTML = 'server error try again later'
                document.getElementById('errors').append(err)
                $('#errors').fadeIn('slow')
                $('.loader').fadeOut()

                setTimeout(() => {
                    $('#errors').fadeOut('slow')
                }, 3500);

                console.error(error);
            }
        },
        goToTop() {
            window.scrollTo({
                top: 0,
                behavior: "smooth",
            });
        },
        setLangCookies() {
            let langCheck = document.cookie.indexOf('lang')

            this.is_cookies = langCheck >= 0 ? true : false

            function getCookie(cname) {
                let name = cname + "=";
                let decodedCookie = decodeURIComponent(document.cookie);
                let ca = decodedCookie.split(';');
                for (let i = 0; i < ca.length; i++) {
                    let c = ca[i];
                    while (c.charAt(0) == ' ') {
                        c = c.substring(1);
                    }
                    if (c.indexOf(name) == 0) {
                        return c.substring(name.length, c.length);
                    }
                }
                return "";
            } // to get an cookie by name ##############################

            if (langCheck !== -1) {
                this.lang = getCookie('lang') // check lang cookie exist ##############################
            }

            if (sessionStorage.getItem("lang"))
                this.lang = sessionStorage.getItem("lang") // check lang session exist ##############################

            sessionStorage.setItem("lang", this.lang); // set lang session ##############################

            let searchParams = new URLSearchParams(window.location.search)
            if (searchParams.has('lang')) {
                this.lang = searchParams.get('lang')
                document.body.classList.add(searchParams.get('lang')) // add lang class ##############################
            } else {
                document.body.classList.add(this.lang) // add lang class ##############################
            }

        },
        getHomeData() {
            this.setLangCookies()
            this.getNews(this.page)
        },
        /**
         * Formats a date string into the format dd/mm/yyyy.
         *
         * @param {string} dateString - The date string to be formatted.
         * @returns {string} The formatted date string or the original input if an error occurs.
        */
        formatDate(dateString) {
            try{
                // Create a new Date object from the date string
                const date = new Date(dateString);

                // Get the day, month, and year from the Date object
                const day = String(date.getDate()).padStart(2, '0');
                const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
                const year = date.getFullYear();

                // Format the date in dd/mm/yyyy
                return `${day}/${month}/${year}`;

            } catch(err){

                return dateString;
            }
        }
    },
    created() {
        this.getHomeData()
    },
    mounted() {
        // $(document).on('click', '.question', function () {
        //     $(this).find('.answer').slideToggle('fast')
        //     $(this).find('.question-value i').toggleClass('fa-chevron-down fa-chevron-up')
        // })
    },
    computed: {
        displayedPages() {
            let startPage = this.page - 2;
            let endPage = this.page + 10;

            // If the startPage is less than 1, adjust it to 1
            if (startPage < 1) {
                startPage = 1;
            }

            // If the endPage is greater than the last_page, adjust it to last_page
            if (endPage > this.last_page) {
                endPage = this.last_page;
            }

            // Calculate the range of pages to be displayed
            const length = endPage - startPage + 1;

            // Generate an array with the range of pages
            return Array.from({ length }, (_, i) => startPage + i);
        },
    },
}
</script>

<style scoped>@import './../assets/css/home.css';</style>