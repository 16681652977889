<template>
  <div class="modal">
    {{ lang }}
    <div class="modal-content">
      <h3 v-if="!isEditRating">{{ lang == "en" ? 'Rate Product': 'تقييم المنتج'}}</h3>
      <h3 v-else>{{lang == 'en' ? 'Edit Rating' : 'تعديل التقييم' }}</h3>


      <div class="users-reviews star-font">
        <i
          v-for="(star, index) in getStars()"
          @click="clickedIndex = index + 1"
          :key="index"
          :class="star"
        ></i>
      </div>
      <textarea
        :placeholder="lang == 'en' ? 'Comment' : 'تعليق'"
        v-model="comment"
      ></textarea>
      <div class="btn-contianer">
        <button type="submit" class="button submit-btn" @click="giveRating()">
          {{ lang == "en" ? "Submit" : "تأكيد" }}
        </button>
        <button class="button cancel-btn" @click="cancel()">
          {{ lang == "en" ? "Cancel" : "الغاء" }}
        </button>


      </div>
    </div>
  </div>
</template>
<script>
global.jQuery = require('jquery');
var $ = global.jQuery;
window.$ = $;

import axios from 'axios';
import { closeModal } from "jenesius-vue-modal";

export default {
  name: "LoginView",
  props: ["lang", "productId", "isCard", "isEditRating", "user_review"],
  data() {
    return {
      comment: null,
      clickedIndex: 0,
    };
  },
  methods: {
    /**
     * Generates an array of star classes based on a given rating.
     * The rating is represented with full, half, and empty stars.
     *
     * @param {number} rating - The rating value, expected to be between 0 and 5.
     * @returns {string[]} An array of star classes representing the rating.
     */
    getStars() {
      // Initialize an empty array to store the star classes
      const stars = [];

      // Calculate the number of full stars (integer part of the rating)
      const fullStars = Math.floor(this.clickedIndex);

      // Determine if there should be a half star (fractional part of the this.clickedIndex)
      const halfStars = this.clickedIndex % 1 >= 0.5 ? 1 : 0;

      // Calculate the number of empty stars to make the total 5
      const emptyStars = 5 - fullStars - halfStars;

      // Add the classes for full stars to the array
      for (let i = 0; i < fullStars; i++) {
        stars.push("fa-solid fa-star active");
      }

      // Add the class for the half star to the array, if applicable
      for (let i = 0; i < halfStars; i++) {
        stars.push("fa-regular fa-star-half-stroke active");
      }

      // Add the classes for empty stars to the array
      for (let i = 0; i < emptyStars; i++) {
        stars.push("fa-regular fa-star");
      }

      // Return the array of star classes
      return stars;
    },
    async giveRating() {
            $('.loader').fadeIn().css('display', 'flex')

            try {
                const response = await axios.post(`https://api.egyptgamestore.com/api/${this.isCard ? 'cards' : 'products'}/${this.productId}/reviews`, {
                  rating: this.clickedIndex,
                  comment: this.comment
                },
                {
                    headers: {
                        "AUTHORIZATION": 'Bearer ' + sessionStorage.getItem('user_token'),
                        "lang": this.lang
                    }
                });
                if (response.data.status === true) {
                  location.reload();
                } else {
                    $('.loader').fadeOut()
                    document.getElementById('errors').innerHTML = ''
                    $.each(response.data.errors, function (key, value) {
                        let error = document.createElement('div')
                        error.classList = 'error'
                        error.innerHTML = value
                        document.getElementById('errors').append(error)
                    });
                    $('#errors').fadeIn('slow')
                    setTimeout(() => {
                        $('input').css('outline', 'none')
                        $('#errors').fadeOut('slow')
                    }, 3500);
                }

            } catch (error) {
                document.getElementById('errors').innerHTML = ''
                let err = document.createElement('div')
                err.classList = 'error'
                err.innerHTML = 'server error try again later'
                document.getElementById('errors').append(err)
                $('#errors').fadeIn('slow')
                $('.loader').fadeOut()

                setTimeout(() => {
                    $('#errors').fadeOut('slow')
                }, 3500);

                console.error(error);
            }
    },
    cancel() {
      this.comment = null;
      this.clickedIndex = 0;
      closeModal();
    },
  },
  created(){
    if (this.isEditRating && this.user_review) {
      this.clickedIndex = this.user_review.rating;
      this.comment = this.user_review.comment;
    }
  }
};
</script>
<style scoped>
.modal {
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.modal-content {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 400px;
  box-sizing: border-box;
}
textarea {
  width: 100%;
  padding: 10px !important;
  font-size: clamp(0.8rem, calc(0.6667rem + 0.5333vw), 1rem);
  border-radius: clamp(0.7rem, calc(0.5rem + 0.8vw), 1rem);
  border: 1px solid #d5dfe4;
  background-color: #f0f3f8;
  color: #0b5177;
  height: 200px;
  resize: none;
}
.users-reviews {
  margin-top: 20px;
  margin-bottom: 20px;
}
.btn-contianer {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
button {
  padding: 0px !important;
  font-size: 13px !important;
  width: 100px;
  height: 40px;
}
.submit-btn {
  margin-left: 4px;
}
.star-font {
  font-size: 24px !important;
}
.cancel-btn {
  background: none;
  color: #e63b12;
  margin-left: 4px;
}
</style>
