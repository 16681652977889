<template>
  <div v-if="showPopup" class="prompt">
    <h2>{{ lang == "en" ? 'See EGS in...' : 'الاستمرار من خلال'}}</h2>
    <div class="option">
      <img src="../assets//imgs/mobile-logo.png" alt="egs Icon" />
      <span :class="{'arabic-text': lang != 'en'}">{{ lang == "en" ? "EGS App" : "EGS تطبيق" }}</span>
      <div class="open-button" @click="openApp()">{{ lang == "en" ? 'Open': 'فتح'}}</div>
    </div>
    <div class="option">
      <img src="@/assets/imgs/google-chrome.jpg" alt="Safari Icon" />
      <span :class="{'arabic-text': lang != 'en'}">{{ lang == "en" ? "Browser" : "المتصفح" }}</span>
      <div class="continue-button" @click="continueOnWeb">{{ lang == "en" ? 'Continue': 'المتابعة'}}</div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showPopup: false,
    };
  },
  created() {
    this.setLangCookies();
  },
  methods: {
    openApp() {
      // Replace with your app's URI scheme
      window.location.href = this.detectMobileOS();
      this.showPopup = false;
    },
    continueOnWeb() {
      this.showPopup = false;
      localStorage.setItem("popupBlocked", "true");
    },
    detectMobileOS() {
      let userAgent = navigator.userAgent || navigator.vendor || window.opera;

      // Check for Android
      if (/android/i.test(userAgent)) {
        return "https://play.google.com/store/apps/details?id=com.egyptgamestore.egs";
      }

      // Check for iOS
      if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return "https://apps.apple.com/eg/app/egs/id1528932896";
      }

      return "https://play.google.com/store/apps/details?id=com.egyptgamestore.egs";
    },
    setLangCookies() {
      let langCheck = document.cookie.indexOf("lang");

      this.is_cookies = langCheck >= 0 ? true : false;

      function getCookie(cname) {
        let name = cname + "=";
        let decodedCookie = decodeURIComponent(document.cookie);
        let ca = decodedCookie.split(";");
        for (let i = 0; i < ca.length; i++) {
          let c = ca[i];
          while (c.charAt(0) == " ") {
            c = c.substring(1);
          }
          if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
          }
        }
        return "";
      } // to get an cookie by name ##############################

      if (langCheck !== -1) {
        this.lang = getCookie("lang"); // check lang cookie exist ##############################
      }

      if (sessionStorage.getItem("lang"))
        this.lang = sessionStorage.getItem("lang"); // check lang session exist ##############################

      sessionStorage.setItem("lang", this.lang); // set lang session ##############################

      let searchParams = new URLSearchParams(window.location.search);
      if (searchParams.has("lang")) {
        this.lang = searchParams.get("lang");
        document.body.classList.add(searchParams.get("lang")); // add lang class ##############################
      } else {
        document.body.classList.add(this.lang); // add lang class ##############################
      }
    },
  },
  mounted() {
    let isShowPopup = JSON.parse(localStorage.getItem("popupBlocked"));
    console.log("isShowPopup=====>", isShowPopup, this.$route.path);
    if (!isShowPopup) {
      this.showPopup = true;
    }
  },
};
</script>

<style scoped>
.prompt {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  /* height: 50%; */
  background-color: #181c1f;
  border-radius: 12px 12px 0px 0px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  /* width: 300px; */
  padding: 20px;
  text-align: center;
  z-index: 10000;
  color: white;
}
.prompt h2 {
  margin-top: 0;
  font-size: 18px;
  font-weight: 600;
}
.option {
  display: flex;
  align-items: center;
  margin: 10px 0;
  padding: 10px;
  border-radius: 8px;
  border: 1px solid #ddd;
  cursor: pointer;
}
.option img {
  width: 24px;
  height: 24px;
  margin-right: 10px;
}
.option span {
  flex: 1;
  text-align: left;
}
.option .open-button {
  background-color: #e63b12;
  color: white;
  padding: 5px 10px;
  border-radius: 8px;
  font-size: 14px;
  width: 80px;
}
.option .continue-button {
  background-color: #018FCC;
  color: white;
  padding: 5px 10px;
  border-radius: 8px;
  font-size: 14px;
  width: 80px;
}
.arabic-text{
  text-align: right !important;
  margin-right: 10px !important;
}
</style>
<style>
/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 769px) {
  .prompt {
    display: none;
  }
}
</style>
